<template>
  <v-navigation-drawer
    :value="$vuetify.breakpoint.mobile ? navDrawerState : true"
    app
    class="main-nav"
    @input="updateDrawerState"
  >
    <v-sheet color="lighten-4" class="pa-4 text-center">
      <AppUserAvatar :user="user" :size="64"></AppUserAvatar>
      <div class="mt-4 font-nunito font-700" v-if="user">{{ user.friendlyName }}</div>
      <div class="font-nunito font-400 grey--text text--lighten-1">{{ user.companyName }}</div>
    </v-sheet>

    <v-divider></v-divider>
    <v-list>
      <v-list-item
        v-for="[icon, text, link, badge] in links"
        :key="icon"
        :to="{ name: link }"
        :class="$route.meta.section == link ? 'section-active' : ''"
      >
        <v-list-item-icon>
          <v-icon color="grey lighten-1">{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-uppercase">
            {{ text }}
            <v-badge v-if="badge" :content="badge" :value="badge" color="red" offset-x="-4" offset-y="4"></v-badge>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppUserAvatar from "@/components/AppUserAvatar.vue";
export default {
  name: "TheMainNav",
  components: { AppUserAvatar },
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("ui", ["navDrawerState"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("timeClock", ["timeClockDuration"]),

    links() {
      return [
        true && ["mdi-view-dashboard-outline", this.$t("c_menu_full.t_home"), "dashboard", null],
        true && [
          "mdi-clock-time-three-outline",
          this.timeClockDuration && this.$route.meta.section !== "timeclock"
            ? this.timeClockDuration
            : this.$t("c_menu_full.t_timeclock"),
          "timeclock",
          null,
        ],
        true && ["mdi-form-select", this.$t("c_menu_full.t_forms"), "forms", null],
        true && ["mdi-note-multiple-outline", this.$t("c_menu_full.t_documents"), "documents", null],
        true && ["mdi-school-outline", this.$t("c_menu_full.t_training"), "training", null],
        true && ["mdi-cube-send", this.$t("c_menu_full.t_messages"), "messages", null],
        true && ["mdi-camera-outline", "log-it", "issues", null],
      ].filter(Boolean);
    },
  },
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    ...mapActions("settings", ["upsertSetting"]),
    ...mapActions("ui", ["navDrawer"]),
    updateDrawerState(state) {
      if (!state) {
        this.navDrawer();
      } else {
        this.navDrawer("open");
      }
    },
  },
};
</script>
<style lang="scss">
.v-list-item--link:before {
  background-color: transparent;
}
.section-active {
  &.v-list-item--link {
    .v-icon {
      color: #468ffb !important;
    }
  }
}
.main-nav {
  top: 54px !important;
  height: calc(100vh - 54px) !important;
}
</style>
