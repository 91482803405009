<template>
  <div>
    <v-bottom-navigation class="nav-bottom" app grow color="primary">
      <v-btn
        class="btn-nav"
        v-for="[icon, text, link, badge] in links"
        :key="icon"
        :to="{ name: link }"
        :class="[
          $vuetify.breakpoint.xsOnly ? 'btn-nav-small' : '',
          $route.meta.section == link ? 'v-btn--active' : $route.meta.section !== 'dashboard' ? 'not-active' : '',
        ]"
        :ripple="false"
      >
        <div
          class="pt-1 text-uppercase font-weight-bold"
          :class="timeClockDuration && $route.meta.section !== 'timeclock' && link === 'timeclock' ? 'clock' : ''"
        >
          {{ text }}
        </div>
        <v-icon>{{ icon }}</v-icon>
        <v-badge v-if="badge" dot color="red" offset-x="-8" offset-y="6"></v-badge>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheFooter",
  components: {},
  computed: {
    ...mapGetters("timeClock", ["timeClockDuration"]),
    ...mapGetters("notifications", ["notifications"]),
    links() {
      return [
        true && ["mdi-view-dashboard-outline", this.$t("c_menu.t_home"), "dashboard", null],
        true && [
          "mdi-clock-time-three-outline",
          this.timeClockDuration && this.$route.meta.section !== "timeclock"
            ? this.timeClockDuration
            : this.$t("c_menu.t_timeclock"),
          "timeclock",
          null,
        ],
        true && ["mdi-form-select", this.$t("c_menu.t_forms"), "forms", null],
        true && ["mdi-note-multiple-outline", this.$t("c_menu.t_documents"), "documents", null],
        true && ["mdi-school-outline", this.$t("c_menu.t_training"), "training", null],
        true && ["mdi-cube-send", this.$t("c_menu.t_messages"), "messages", this.notifications?.length > 0],
        true && ["mdi-camera-outline", "log-it", "issues", null],
      ].filter(Boolean);
    },
  },
};
</script>
<style lang="scss">
.v-btn--active.no-active:not(:hover)::before {
  opacity: 0 !important;
  background-color: transparent !important;
}
.v-btn--active::before {
  background-color: transparent !important;
}
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 65px;
}
.theme--dark.v-bottom-navigation .v-btn.not-active.v-btn--active {
  color: rgba(255, 255, 255, 0.7) !important;
}
.theme--light.v-bottom-navigation .v-btn.not-active.v-btn--active {
  color: rgba(0, 0, 0, 0.6) !important;
}

.nav-bottom {
  border-top: 1px solid #ccc;
  height: auto !important;
  .clock {
    margin: 0 auto;
  }
  .btn-nav {
    padding-top: 7px !important;
    padding-bottom: constant(safe-area-inset-bottom) !important; /* move footer up on iPhone X - iOS 11.0 */
    padding-bottom: calc(10px + env(safe-area-inset-bottom)) !important; /* move footer up on iPhone X - iOS 11.2 */
  }
  .btn-nav-small {
    font-size: 9px !important;
    font-family: "roboto", sans-serif !important;
    min-width: 50px !important;
  }
  .v-btn:before {
    background-color: transparent;
  }
  @media (max-width: 449px) {
    .btn-nav-small {
      font-size: 8px !important;
    }
  }
}
</style>
